.project-landing-section {
  width: 100vw;
  height: 100vh;
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-landing-title {
  width: 100vw;
  height: 75vh;
  color: rgb(62, 72, 117);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(62, 72, 117);
  border-radius: 0 400px 400px 0;
  border-top: 5px solid #ededed;
  border-right: 5px solid #ededed;
  border-bottom: 5px solid #ededed;
}

.project-landing-title svg {
  color: white;
  animation: bounceX 3s ease-out;
  animation-iteration-count: infinite;
}

.project-landing-circle {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.project-content {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes bounceX {
  0% {
    transform: translateX(0)
  }

  50% {
    transform: translateX(-80%)
  }

  100% {
    transform: translateX(0);
  }
}

@media screen and (min-width: 720px) {
  .project-landing-title {
    background-color: rgb(62, 72, 117);
    width: 100vw;
    height: 400px;
    border-radius: 200px;
    border: 10px solid #ededed;
    max-width: 900px;
  }

  .project-landing-title svg {
    left: -80px;
    color: white;
  }

  .project-landing-circle {
    background-color: rgb(62, 72, 117);
  }

  .project-landing-circle h2 {
    font-size: 64px;
    color: white;
  }
}

@media screen and (min-width: 720px) {

  .project-landing-title {}
}