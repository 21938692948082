.bold {
  font-weight: 700;
}

.landing-section {
  width: 100vw;
  height: 100vh;
  display: flex;
  /* flex-direction: column; */
  /* margin-top: 100px; */
  justify-content: center;
  align-items: flex-start;
  background-color: #ededed;
}

.landing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 75vh;
}

.landing-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75vh;
  background-color: rgb(62, 72, 117);
  border-radius: 0 0 200px 200px;
}

.landing-div a {
  align-self: center;
}

.landing-div svg {
  position: relative;
  top: 130px;
  color: rgb(62, 72, 117);
  animation: bounce 3s ease-out;
  animation-iteration-count: infinite;
}

.landing-circle {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  /* border: 3px solid black; */
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  font-size: 90px;
  font-weight: 500;
  color: rgb(62, 72, 117);
}

.name-tag-div {
  width: 75vw;
  color: white;
}

.name-tag-div h1 {
  font-size: 48px;
  margin: 1px;
}

.thin {
  font-weight: 400;
}

@keyframes bounce {
  0% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(-80%)
  }

  100% {
    transform: translateY(0);
  }
}

@media screen and (min-width: 720px) {
  .landing-div {
    border-radius: 0;
    height: 60vh;
    padding: 0 20px;
  }

  .landing-content {
    flex-direction: row;
    height: 60vh;
    width: 80vw;
    align-self: center;
  }

  .landing-section {
    align-items: center;
  }

  .landing-circle {
    padding: 0 20px;
    margin: 0 10px;
  }

  .name-tag-div p {
    max-width: 380px;
  }

  .name-tag-div h1 {
    font-size: 60px;
  }
}

@media screen and (min-width: 950px) {
  .landing-content {
    max-width: 900px;
  }
}