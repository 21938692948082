.extra-tech ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.extra-tech ul li {
  border: 1px solid rgb(62, 72, 117);
  border-radius: 3px;
  padding: 4px;
  margin: 2px 6px;
}

.links a {
  text-decoration: none;
  color: rgb(62, 72, 117);
}

.links a:hover {
  font-weight: 700;
  color: rgb(189, 89, 52);
}

.handler-section {
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  color: rgb(62, 72, 117);
  display: flex;
  flex-direction: column;
  /* margin-top: 100px; */
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px;
}

.handler-section h2 {
  /* padding-top: 100px; */
  font-family: 'EB Garamond', serif;
  /* margin-bottom: 0; */
}

.logo-arrows {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  padding-top: 50px;
}

.logo-arrows a {
  text-decoration: none;
  color: rgb(62, 72, 117);
}

.links p {
  padding: 0;
}

.handler-circle {
  width: 100vw;
  height: 375px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 7px solid #ededed;
  border-radius: 200px;
  margin: 0 10px;
  background-color: rgb(62, 72, 117);
  color: white;
  text-align: center;
  padding: 10px 28px;
}

.handler-circle button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.handler-circle p {
  padding: 0 5px;
}

.handler-circle button:hover {
  box-shadow: 0px 0px 10px #ededed;
  border-radius: 10px;
}

.handler-circle img {
  width: 100px;
  margin-top: 5px;
  border-radius: 50%;
  animation: pulse 1s linear;
  animation-iteration-count: infinite;
  z-index: -1;
}

.tech-logos {
  width: 50%;
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (min-width: 500px) {
  .handler-circle {
    font-size: 20px;
  }

  .handler-section h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 720px) {
  .handler-circle {
    max-width: 900px;
    font-size: 24px;
  }

}