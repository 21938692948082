.links a {
  text-decoration: none;
  color: rgb(62, 72, 117);
}

.links a:hover {
  font-weight: 700;
  color: rgb(189, 89, 52);
}

.linus-section {
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  color: rgb(62, 72, 117);
  display: flex;
  flex-direction: column;
  /* margin-top: 100px; */
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px;
}

.linus-section h2 {
  padding-top: 10px;
  padding-bottom: 5px;
  width: 75px;
  text-align: center;
  font-family: 'Josefin Slab', serif;
  border-top: 1px solid rgb(62, 72, 117);
  border-bottom: 1px solid rgb(62, 72, 117);
  border-radius: 5px;
}

.links p {
  padding: 0;
}

.linus-circle {
  width: 100vw;
  height: 375px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 7px solid #ededed;
  border-radius: 200px;
  margin: 0 10px;
  background-color: rgb(62, 72, 117);
  color: white;
  text-align: center;
  padding: 10px 35px;
}

.linus-circle button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.linus-circle button:hover {
  box-shadow: 0px 0px 15px #ededed;
  border-radius: 10px;
}

.linus-circle img {
  width: 100px;
  background-color: white;
  border-radius: 50%;
  animation: pulse 1s linear;
  animation-iteration-count: infinite;
}

.tech-logos {
  width: 50%;
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
}

@media screen and (min-width: 500px) {
  .linus-circle {
    font-size: 20px;
  }

  .linus-section h2 {
    font-size: 30px;
    border-top: 2px solid rgb(62, 72, 117);
    border-bottom: 2px solid rgb(62, 72, 117);
  }
}

@media screen and (min-width: 720px) {
  .linus-circle {
    max-width: 900px;
    font-size: 24px;
  }
}