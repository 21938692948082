.blue {
  color: rgb(62, 72, 117);
  transition: ease-in-out 0.5s;
}

.white {
  color: white;
  transition: ease-in-out 0.5s;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0.5rem 2.5rem; */
  position: absolute;
  z-index: 1;
  width: 100vw;
  color: white;
  height: 64px;
}

.navbar-hidden {
  animation: moveUp 0.5s ease-in-out;
  display: none;
}

.navbar-sticky {
  background-color: rgb(62, 72, 117);
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 1px 1px 1px #222;
  animation: moveDown 0.5s ease-in-out;
}

.navbar--logo-holder {
  margin-right: 20px;
  z-index: 1;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }

  to {
    transform: translateY(0rem);
  }
}

@keyframes moveUp {
  from {
    transform: translateY(0rem);
  }

  to {
    transform: translateY(-5rem);
  }
}

/*burger  menu*/
#menuToggle {
  display: block;
  position: relative;
  top: 2px;
  left: 20px;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #ededed;

  transition: color 0.3s ease;
}

#menuToggle a:hover {
  /* text-shadow: 4px 10px 10px black; */
  animation: offPage 0.2s linear;
  animation-fill-mode: forwards;
}

@keyframes offPage {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
    text-shadow: 4px 10px 15px black;
  }
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: white;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: rgb(62, 72, 117);
}

#menuToggle input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  width: 109vw;
  height: 107vh;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: rgb(189, 89, 52);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 40px 0;
  font-size: 50px;
  text-align: center;
}

#menuToggle input:checked~ul {
  transform: none;
}

@media screen and (min-width: 720px) {
  .navbar--link {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    align-items: center;
    width: 250px;
  }

  .navbar--link-item {
    margin: 0;
    padding: 0 10px;
    cursor: pointer;
    font-size: 18px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .navbar--link-item:hover {
    background-color: rgb(181, 133, 78);
  }
}