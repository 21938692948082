.slides-section {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.slides-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slides-main button {
  background-color: transparent;
  border: none;
  color: rgb(62, 72, 117);
  align-self: flex-end;
  margin: 4px 1px;
}

.slides-main img {
  border: 1px solid black;
  position: relative;
  max-height: 600px;
  max-width: 85%;
  /* z-index: auto; */
}

.prev-next {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.prev-next button {
  align-self: center;
}