* {
  box-sizing: border-box;
}

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

body {
  font-family: "Josefin Sans", sans-serif;
  background-color: #ededed;
}

.snap {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.projects {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.linus-section,
.spanimal-section,
.qtrail-section,
.handler-section {
  padding-top: 50px;
  background-color: #ededed;
}