.about-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: flex-start; */
  height: 100vh;
}

.header-space {
  height: 64px;
  width: 100%;
}

.pic {
  border-radius: 10px;
  width: 180px;
  border: 2px solid rgb(62, 72, 117);
}

.about-me {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(62, 72, 117);
  background-color: #ededed;
  margin-top: -1px;
}

@media screen and (min-width: 720px) {

  .about-me {
    flex-direction: row;
    align-items: center;
    height: 100vh;
    justify-content: space-evenly;
  }

  .about-me p {
    font-size: 18px;
    padding: 0 10px;
  }

  .about-circle img {
    width: 200px;
    margin-left: 15px;
  }
}

@media screen and (min-width: 950px) {
  .about-circle img {
    width: 250px;
  }

  .about-me p {
    font-size: 20px;
    padding: 0 10px;
    max-width: 700px;
  }
}