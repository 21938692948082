.contact-section {
  width: 100%;
  height: 95vh;
  background-color: #ededed;
  display: flex;
  /* margin-top: 100px; */
  justify-content: center;
  align-items: flex-end;
}

.contact-div {
  background-color: rgb(62, 72, 117);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 80vh;
  width: 100vw;
  border-radius: 200px 200px 0 0;
  color: white;
  font-size: 24px;
}

.contact-div img {
  width: 180px;
  border: 2px solid #ededed;
  border-radius: 5px;
  margin: 8px;
}

.contact-div a {
  text-decoration: none;
  color: white;
}

.contact-div a:hover {
  font-weight: 600;
  font-size: 30px;
  color: rgb(189, 89, 52);
}

.contact-pic-text {
  display: flex;
  flex-direction: row;
}

.contact-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70vw;
  position: relative;
  /* top: 10px; */
}

.contact-list a {
  color: white;
  font-size: 40px;
}

.contact-list a:hover {
  color: rgb(189, 89, 52);
  font-size: 50px;
}

.contact-list a:active {
  color: white;
}

.contact-text {
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-top: 20px;
}

.contact-form input {
  width: 100%;
  height: 30px;
  margin: 5px;
  border-radius: 5px;
}

.contact-form textarea {
  height: 100px;
  margin: 5px;
  width: 100%;
  border-radius: 5px;
}

.contact-form button {
  width: 60%;
  height: 30px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 5px;
}

@media screen and (min-width: 720px) {
  .contact-section {
    align-items: center;
  }

  .contact-div {
    border-radius: 0;
    max-height: 60vh;
  }

  .contact-pic-text {
    width: 80vw;
    max-width: 660px;
    justify-content: space-between;
  }

  .contact-div img {
    width: 250px;
  }

  .contact-text {
    font-size: 32px;
  }

  .contact-text a {
    align-self: center;
  }
}